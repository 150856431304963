export enum ERedirectPayloadFields {
  JwtToken = 'jwt_token',
  TokenUuid = 'token_uuid',
  SupplierOrderId = 'supplier_order_id',
  SupplierId = 'supplier_id',
  OrderId = 'order_id',
  Id = 'id',
  Uuid = 'uuid',
  OrderStatus = 'order_status',
  To = 'to',
  UserType = 'user_type',
  OrderNumber = 'order_number',
  OfferPolicyId = 'offer_policy_id',
  NewTab = 'new_tab',
  TokenId= 'token_id'
}
